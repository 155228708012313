<style lang="scss">
#integrations-app {
  .margin-bottom {
    margin-bottom: 15px;
  }
  .img-rounded {
    border-radius: 5px;
  }
  .service-setup-item {
    background: white;
    display: block;
    height: 250px;
    border-radius: 5px;
    border: solid 2px #e6e6e6;
    color: #674cab;
    text-decoration: none !important;

    & > span {
      display: block;
    }
    .service-setup-item-top {
      text-align: center;
      font-size: 90px;
      line-height: 20px;
      border-bottom: solid 2px #e6e6e6;
      padding: 10px;
    }
    .service-setup-item-bottom {
      position: relative;
      padding: 15px;

      .service-setup-item-heading {
        display: block;
        font-size: 20px;
        font-weight: 500;
      }

      .service-setup-item-desc {
        display: block;
        font-size: 16px;
        line-height: 18px;
        color: #9b9b9b;
        font-weight: 500;
        margin-top: 5px;
      }

      .service-setup-item-nav {
        display: inline-block;
        background: #674cab;
        color: #fff;
        padding: 7px;
        border-radius: 100%;
        position: absolute;
        right: 15px;
        margin-top: -38px;
        font-size: 20px;
        width: 42px;
        text-align: center;
        &.service-setup-item-prev {
          z-index: 1;
          i {
            color: white;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div id="integrations-app">
    <div class="row" v-for="(item, index) in integrations" :key="index">
      <div class="col-sm-12">
        <h3>{{ item.title }}</h3>
      </div>
      <div
        class="col-sm-4 col-lg-4 margin-bottom"
        v-for="(dItem, index_) in item.data"
        :key="index_"
      >
        <router-link
          :to="{
            name: dItem.route_name,
            params: { client_slug: client_slug },
            query: { title: dItem.title, icon: dItem.img }
          }"
          class="service-setup-item"
        >
          <span class="service-setup-item-top">
            <img
              :src="require(`@/assets/images/icon/png/${dItem.img}`)"
              class="img-rounded"
              :width="dItem.img_width"
            />
          </span>
          <span class="service-setup-item-bottom">
            <span class="service-setup-item-nav service-setup-item-prev"
              ><i class="fa fa-eye"></i
            ></span>
            <span class="service-setup-item-heading">{{ dItem.title }}</span>
            <span class="service-setup-item-desc">{{ dItem.description }}</span>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  data() {
    return {
      client_slug: "",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Integrations",
          route: ""
        }
      ],
      integrations: require("@/assets/json/integrations_menu.json")
    };
  },
  created() {
    this.client_slug = this.$route.params.client_slug;
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  }
};
</script>
